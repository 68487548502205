import styled from "styled-components"

import { NoiseAlertSettingCard } from "src/components/SettingsProfiles/NoiseAlertSettingCard"
import { IProfileResponse } from "src/data/homeProfiles/types/homeProfilesTypes"
import { IOrganization } from "src/data/organizations/types/organizationTypes"
import { spacing } from "src/ui/spacing"

export function NoiseAlertTab({
  profileResponse,
  organizationResponse,
}: {
  profileResponse: IProfileResponse
  organizationResponse: IOrganization
}) {
  return (
    <CardsContainer>
      <NoiseAlertSettingCard
        organizationResponse={organizationResponse}
        profileResponse={profileResponse}
        variant="first_alert"
      />
      <NoiseAlertSettingCard
        organizationResponse={organizationResponse}
        profileResponse={profileResponse}
        variant="second_alert"
      />
      <NoiseAlertSettingCard
        organizationResponse={organizationResponse}
        profileResponse={profileResponse}
        variant="third_alert"
      />
    </CardsContainer>
  )
}

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.M};
`
