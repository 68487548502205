import styled from "styled-components"

import { SettingText } from "src/components/Settings/Setting/SettingText"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { usePatchOrganization } from "src/data/organizations/queries/organizationQueries"
import {
  IOrganization,
  IOrganizationPatch,
} from "src/data/organizations/types/organizationTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { spacing } from "src/ui/spacing"

const AUTOCALL_CONTENT_MAXLEN = 2000
type TLevel = 1 | 2 | 3

export function AutoCallMessage({ level }: { level: TLevel }) {
  const { t, langKeys } = useTranslate()
  const { org } = useOrganization()
  const patchOrganization = usePatchOrganization()
  const smsText = getSmsText(level, org) ?? ""

  const title = t(langKeys.guest_connect_autocall_message_header)
  const info = (
    <Info>
      {t(langKeys.profiles_noise_monitoring_sms_guest_message_description)}
    </Info>
  )

  return (
    <Box>
      <SettingText
        title={title}
        description={info}
        onSave={async (newVal) => {
          try {
            await patchOrganization.mutateAsync({
              orgId: org.id,
              body: getPatchData(level, newVal),
            })

            return {
              isSuccess: true,
            }
          } catch (e) {
            return {
              isSuccess: false,
              message: t(langKeys.failed_general_error_title),
            }
          }
        }}
        value={smsText}
        textFieldProps={{ minRows: 2, multiline: true }}
        inputProps={{ maxLength: AUTOCALL_CONTENT_MAXLEN }}
      />
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.M};
`

const Info = styled.div`
  display: grid;
  gap: ${spacing.S};
`

function getSmsText(level: TLevel, org: IOrganization) {
  switch (level) {
    case 1:
      return org.guest_communication?.disturbance_monitoring?.first_notice
        ?.autocall_content
    case 2:
      return org.guest_communication?.disturbance_monitoring?.second_notice
        ?.autocall_content
    case 3:
      return org.guest_communication?.disturbance_monitoring?.third_notice
        ?.autocall_content
  }
}

function getPatchData(
  level: TLevel,
  autocall_content: string
): IOrganizationPatch {
  switch (level) {
    case 1:
      return {
        guest_communication: {
          disturbance_monitoring: {
            first_notice: { autocall_content },
          },
        },
      }
    case 2:
      return {
        guest_communication: {
          disturbance_monitoring: {
            second_notice: { autocall_content },
          },
        },
      }
    case 3:
      return {
        guest_communication: {
          disturbance_monitoring: {
            third_notice: { autocall_content },
          },
        },
      }
  }
}
