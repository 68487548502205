import { IProfileResponse } from "src/data/homeProfiles/types/homeProfilesTypes"

export type TNoiseLevel = 1 | 2 | 3

export function isAutoCallEnabled(
  level: TNoiseLevel,
  data: IProfileResponse | undefined
) {
  const d = data?.disturbance_monitoring
  switch (level) {
    case 1:
      return d?.first_notice_autocall_enabled
    case 2:
      return d?.second_notice_autocall_enabled
    case 3:
      return d?.third_notice_autocall_enabled
    default:
      throw new Error(`Invalid level '${level}'`)
  }
}

// ts-prune-ignore-next
export function isCallAssistEnabled(
  level: TNoiseLevel,
  data: IProfileResponse | undefined
) {
  const d = data?.disturbance_monitoring
  switch (level) {
    case 1:
      return false
    case 2:
      return false
    case 3:
      return d?.third_notice_autocall_enabled
    default:
      throw new Error(`Invalid level '${level}'`)
  }
}
