import { IProfileResponse } from "src/data/homeProfiles/types/homeProfilesTypes"
import { IOrganizationResponse } from "src/data/organizations/types/organizationTypes"
import {
  TNoiseAlertSettings,
  TNoiseAlertVariant,
} from "src/data/profileSettings/noiseAlertTypes"

export function selectSettings(
  variant: TNoiseAlertVariant,
  organizationResponse: IOrganizationResponse,
  profileResponse: IProfileResponse
): TNoiseAlertSettings {
  const ids = {
    profileId: profileResponse.id,
    orgId: organizationResponse.id,
  } as const

  const orgSettings =
    organizationResponse?.guest_communication?.disturbance_monitoring
  const profileSettings = profileResponse?.disturbance_monitoring

  const settings: Record<TNoiseAlertVariant, TNoiseAlertSettings> = {
    first_alert: {
      autocallContent: orgSettings?.first_notice?.autocall_content ?? "",
      smsContent: orgSettings?.first_notice?.sms_content ?? "",
      smsEnabled: profileSettings?.first_notice_sms_enabled ?? false,
      autocallEnabled: profileSettings?.first_notice_autocall_enabled ?? false,
      flashEnabled: profileSettings?.first_notice_flash_and_sound ?? false,
      ...ids,
    },
    second_alert: {
      autocallContent: orgSettings?.second_notice?.autocall_content ?? "",
      smsContent: orgSettings?.second_notice?.sms_content ?? "",
      smsEnabled: profileSettings?.second_notice_sms_enabled ?? false,
      autocallEnabled: profileSettings?.second_notice_autocall_enabled ?? false,
      flashEnabled: profileSettings?.second_notice_flash_and_sound ?? false,
      ...ids,
    },
    third_alert: {
      autocallContent: orgSettings?.third_notice?.autocall_content ?? "",
      smsContent: orgSettings?.third_notice?.sms_content ?? "",
      smsEnabled: profileSettings?.third_notice_sms_enabled ?? false,
      autocallEnabled: profileSettings?.third_notice_autocall_enabled ?? false,
      flashEnabled: profileSettings?.third_notice_flash_and_sound ?? false,
      ...ids,
    },
  }

  return settings[variant]
}
