import { NoiseAlertTab } from "src/components/SettingsProfiles/NoiseAlertTab"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { IProfileResponse } from "src/data/homeProfiles/types/homeProfilesTypes"
import { IOrganization } from "src/data/organizations/types/organizationTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { ITab } from "src/ui/Tabs/Tab"
import { TabPanel } from "src/ui/Tabs/TabPanel"
import { Tabs } from "src/ui/Tabs/Tabs"

export function MonitoringProfileSettings({
  profileResponse,
  organizationResponse,
}: {
  profileResponse: IProfileResponse
  organizationResponse: IOrganization
}) {
  //TODO: This component will be populated with this task: GXP-364

  const { tPlaceholder } = useTranslate()

  const hasUsedEureka = useFeatureAvailability({ feature: "eureka" })

  const tabs: ITab[] = [
    {
      id: "noise",
      label: tPlaceholder("Noise alerts"),
      view: (
        <NoiseAlertTab
          profileResponse={profileResponse}
          organizationResponse={organizationResponse}
        />
      ),
    },
    {
      id: "security",
      label: tPlaceholder("Security alarm"),
      view: <div>Security alarm</div>,
    },
    {
      id: "smoke",
      label: tPlaceholder("Smoke & CO alarm"),
      view: <div>Smoke & CO alarm</div>,
      hidden: !hasUsedEureka.available,
    },
  ].filter((it) => !it.hidden)

  return (
    <Tabs labels={tabs}>
      {tabs.map((tab) => (
        <TabPanel key={tab.label}>{tab.view}</TabPanel>
      ))}
    </Tabs>
  )
}
