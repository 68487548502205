import { useState } from "react"
import styled from "styled-components"

import { UpgradeBlockerDialog } from "src/components/FeatureBlockers/UpgradeBlockerDialog"
import { SettingToggle } from "src/components/Settings/Setting/SettingToggle"
import {
  isAutoCallEnabled,
  TNoiseLevel,
} from "src/components/SettingsProfiles/NoiseMonitoring/noiseMonitoringLogic"
import { usePostCallAssistToggled } from "src/data/analytics/queries/CallAssistAnalyticsQueries"
import {
  useFetchCallAssistState,
  usePatchCallAssistState,
} from "src/data/callAssist/queries/callAssistQueries"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { useFetchProfile } from "src/data/homeProfiles/queries/HomeProfilesQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MDialog } from "src/ui/Dialog/MDialog"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { spacing } from "src/ui/spacing"

type TLevel = TNoiseLevel

export function CallAssistToggle({
  profileId,
  level,
}: {
  profileId: string
  level: TLevel
}) {
  const { t, langKeys } = useTranslate()
  const { navigate } = useRouter()

  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false)
  const fetchProfile = useFetchProfile({ id: profileId })
  const patchCallAssistState = usePatchCallAssistState()
  const autoCallEnabled = isAutoCallEnabled(level, fetchProfile.data)

  const postCallAssistToggledTracking = usePostCallAssistToggled()
  const fetchCallAssistState = useFetchCallAssistState({ profileId, level })
  const callAssistEnabled = !!fetchCallAssistState.data?.enabled

  const autoCallIsBlockingInfo = t(
    langKeys.profiles_cant_turn_on_call_assist_body
  )
  const [showAutoCallBlockingDialog, setShowAutoCallBlockingDialog] =
    useState(false)

  const callAssistState = useCallAssistState()

  const getValue = () => {
    if (fetchProfile.isInitialLoading) return false
    if (patchCallAssistState.isLoading) {
      const optimisticState = !!patchCallAssistState.variables?.enabled
      return optimisticState
    }
    return !!callAssistEnabled
  }
  const value = getValue()

  const title = t(langKeys.profiles_human_caller_title)

  const info = (
    <Info>
      <div>
        {t(langKeys.profiles_human_caller_body)}{" "}
        <ExternalLink href="https://www.minut.com/product/minut-monitoring">
          {t(langKeys.learn_more)}
        </ExternalLink>
      </div>

      <MDialog
        title={t(langKeys.profiles_cant_turn_on_call_assist_title)}
        open={showAutoCallBlockingDialog}
        onClose={() => setShowAutoCallBlockingDialog(false)}
      >
        <div>{autoCallIsBlockingInfo}</div>
      </MDialog>
    </Info>
  )

  return (
    <SettingToggle
      title={title}
      description={info}
      value={value}
      onSave={async (newValue) => {
        if (!!newValue && autoCallEnabled) {
          // inform user that call assist cannot be used together with autocall
          setShowAutoCallBlockingDialog(true)

          return {
            isSuccess: true,
          }
        }

        if (callAssistState.canUse) {
          try {
            const enabled = !!newValue
            await postCallAssistToggledTracking.mutateAsync({
              new_toggle_status: enabled,
            })
            await patchCallAssistState.mutateAsync({
              enabled,
              level,
              profileId,
            })

            return {
              isSuccess: true,
            }
          } catch (error) {
            return {
              isSuccess: false,
            }
          }
        }

        if (!callAssistState.canUse && callAssistState.canPurchase) {
          navigate(Routes.CallAssistActivate.location()) // redirect to activation wizard

          return {
            isSuccess: true,
          }
        }

        if (!callAssistState.canUse && !callAssistState.canPurchase) {
          setShowUpgradeDialog(true)
          return {
            isSuccess: true,
          }
        }

        return {
          isSuccess: true,
        }
      }}
      toggleProps={{
        trueVal: true,
        falseVal: false,
        toolTipText: !!autoCallEnabled ? autoCallIsBlockingInfo : undefined,
      }}
      featureBlocker={{
        dialog: (
          <UpgradeBlockerDialog
            open={true}
            onClose={() => setShowUpgradeDialog(false)}
          />
        ),
        showDialog: showUpgradeDialog,
        showIcon: !callAssistState.canUse && !callAssistState.canPurchase,
      }}
    ></SettingToggle>
  )
}

const Info = styled.div`
  display: grid;
  gap: ${spacing.S};
`

function useCallAssistState() {
  const useCallAssist = useFeatureAvailability({
    feature: "use_call_assist",
  })
  const purchaseCallAssist = useFeatureAvailability({
    feature: "purchase_call_assist",
  })
  const canUse = useCallAssist.available
  const canPurchase = purchaseCallAssist.available
  const isLoading = !useCallAssist.ready || !purchaseCallAssist.ready

  return { canUse, canPurchase, isLoading }
}
