import { useState } from "react"
import styled from "styled-components"

import { useMutation } from "@tanstack/react-query"

import { SettingToggle } from "src/components/Settings/Setting/SettingToggle"
import { AutoCallMessage } from "src/components/SettingsProfiles/NoiseMonitoring/AutoCallMessage"
import {
  isAutoCallEnabled,
  TNoiseLevel,
} from "src/components/SettingsProfiles/NoiseMonitoring/noiseMonitoringLogic"
import { useFetchCallAssistState } from "src/data/callAssist/queries/callAssistQueries"
import {
  useFetchProfile,
  usePatchProfile,
} from "src/data/homeProfiles/queries/HomeProfilesQueries"
import { IProfileRequest } from "src/data/homeProfiles/types/homeProfilesTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MDialog } from "src/ui/Dialog/MDialog"
import { spacing } from "src/ui/spacing"

type TLevel = TNoiseLevel

export function AutoCallToggle({
  profileId,
  level,
}: {
  profileId: string
  level: TLevel
}) {
  const { t, langKeys } = useTranslate()

  const fetchAutocallState = useFetchAutocallState({ profileId, level })
  const autocallEnabled = fetchAutocallState.data
  const patchAutoCallState = usePatchAutocallState()

  const fetchCallAssistState = useFetchCallAssistState({ profileId, level })
  const callAssistEnabled = !!fetchCallAssistState.data?.enabled

  const callAssistIsBlockingInfo = t(
    langKeys.profiles_cant_turn_on_autocall_body
  )

  const [showCallAssistBlockingDialog, setShowCallAssistBlockingDialog] =
    useState(false)

  const getValue = () => {
    if (fetchAutocallState.isInitialLoading) return false
    if (patchAutoCallState.isLoading) {
      const optimisticState = !!patchAutoCallState.variables?.enabled
      return optimisticState
    }
    return !!autocallEnabled
  }
  const value = getValue()

  const title = t(langKeys.guest_connect_autocall_title)
  const info = (
    <Info>
      <div>{t(langKeys.guest_connect_autocall_short_description)}</div>

      <MDialog
        title={t(langKeys.profiles_cant_turn_on_autocall_title)}
        open={showCallAssistBlockingDialog}
        onClose={() => setShowCallAssistBlockingDialog(false)}
      >
        {callAssistIsBlockingInfo}
      </MDialog>
    </Info>
  )

  return (
    <SettingToggle
      title={title}
      description={info}
      value={value}
      onSave={async (v) => {
        if (!!v && callAssistEnabled) {
          setShowCallAssistBlockingDialog(true)

          return {
            isSuccess: true,
          }
        }
        try {
          await patchAutoCallState.mutateAsync({
            level,
            profileId,
            enabled: !!v,
          })
          return {
            isSuccess: true,
          }
        } catch (error) {
          return {
            isSuccess: true,
          }
        }
      }}
      toggleProps={{
        trueVal: true,
        falseVal: false,
        toolTipText: !!callAssistEnabled ? callAssistIsBlockingInfo : undefined,
      }}
    >
      <ChildBox>
        <AutoCallMessage level={level} />
      </ChildBox>
    </SettingToggle>
  )
}

const ChildBox = styled.div`
  margin-left: ${spacing.L};
  margin-top: ${spacing.L};
  margin-right: ${spacing.M};
`

const Info = styled.div`
  display: grid;
  gap: ${spacing.S};
`

function useFetchAutocallState({
  profileId,
  level,
}: {
  level: 1 | 2 | 3
  profileId: string
}) {
  const fetchProfile = useFetchProfile({
    id: profileId,
    options: {
      select(data) {
        return isAutoCallEnabled(level, data)
      },
    },
  })
  return fetchProfile
}

function usePatchAutocallState() {
  const patchProfile = usePatchProfile()

  function patchAutoCallState({
    profileId,
    enabled,
    level,
  }: {
    profileId: string
    enabled: boolean
    level: 1 | 2 | 3
  }) {
    const data: IProfileRequest = (() => {
      switch (level) {
        case 1:
          return {
            disturbance_monitoring: { first_notice_autocall_enabled: enabled },
          }
        case 2:
          return {
            disturbance_monitoring: { second_notice_autocall_enabled: enabled },
          }
        case 3:
          return {
            disturbance_monitoring: { third_notice_autocall_enabled: enabled },
          }
      }
    })()
    return patchProfile.mutateAsync({
      id: profileId,
      data,
    })
  }

  return useMutation({
    mutationFn: patchAutoCallState,
  })
}
