import {
  AutoCallNode1,
  AutoCallNode2,
  AutoCallNode3,
  CallAssistNode3,
} from "src/components/SettingsProfiles/NoiseMonitoring/NoiseMonitoringNodes"
import { EditType } from "src/data/editField/editFieldTypes"
import {
  FlashSoundToggle1,
  FlashSoundToggle2,
  FlashSoundToggle3,
} from "src/data/homeProfileSettings/logic/sectionNoiseMonitoring/flashSound"
import {
  SmsToggleSection1,
  SmsToggleSection2,
  SmsToggleSection3,
} from "src/data/homeProfileSettings/logic/sectionNoiseMonitoring/smsToggle"
import {
  NotifyResponders,
  NotifyTeamMembers,
} from "src/data/homeProfileSettings/logic/shared/genericFields"
import { ISectionProfile } from "src/data/homeProfileSettings/types/homeProfilesSettingTypes"
import { langKeys } from "src/i18n/langKeys"

const SectionAboveThresholdFirstNotice: ISectionProfile = {
  type: EditType.SECTION,
  title: (t) =>
    t(langKeys.profiles_noise_monitoring_timeline_first_warning_header),
  key: "section-above_threshold1",
  description: (t) =>
    t(langKeys.profiles_noise_monitoring_timeline_first_warning_footer),
  contents: [
    NotifyTeamMembers,
    SmsToggleSection1,
    AutoCallNode1,
    FlashSoundToggle1,
  ],
}

const SectionAboveThresholdSecondNotice: ISectionProfile = {
  type: EditType.SECTION,
  title: (t) =>
    t(langKeys.profiles_noise_monitoring_timeline_second_warning_header),
  key: "section-above_threshold2",
  description: (t) =>
    t(
      langKeys.profiles_noise_monitoring_timeline_sencond_third_warning_footer,
      { 0: "10" }
    ),
  contents: [
    NotifyTeamMembers,
    SmsToggleSection2,
    AutoCallNode2,
    FlashSoundToggle2,
  ],
}

const SectionAboveThresholdThirdNotice: ISectionProfile = {
  type: EditType.SECTION,
  title: (t) =>
    t(langKeys.profiles_noise_monitoring_timeline_third_warning_header),
  key: "section-above_threshold3",
  description: (t) =>
    t(
      langKeys.profiles_noise_monitoring_timeline_sencond_third_warning_footer,
      { 0: "20" }
    ),
  contents: [
    NotifyTeamMembers,
    SmsToggleSection3,
    AutoCallNode3,
    CallAssistNode3,
    FlashSoundToggle3,
    NotifyResponders,
  ],
}

export const SectionNoiseMonitoring: ISectionProfile = {
  type: EditType.SECTION,
  title: (t) => t(langKeys.sound_noise_monitoring),
  key: "section-noise_monitoring",
  description: "",
  contents: [
    SectionAboveThresholdFirstNotice,
    SectionAboveThresholdSecondNotice,
    SectionAboveThresholdThirdNotice,
  ],
}
