import { EditType } from "src/data/editField/editFieldTypes"
import { organizationEndpointUrl } from "src/data/homeProfileSettings/logic/shared/endpoints"
import { SMS_PLACEHOLDER } from "src/data/homeProfileSettings/logic/shared/homeProfileText"
import {
  ITextInputProfile,
  TProfileSettings,
} from "src/data/homeProfileSettings/types/homeProfilesSettingTypes"
import {
  IOrganizationPatch,
  SMS_CONTENT_MAXLEN,
} from "src/data/organizations/types/organizationTypes"
import { langKeys } from "src/i18n/langKeys"

const SmsContentsMixin = {
  title: langKeys.guest_connect_sms_guest_message_header,
  defaultValue: SMS_PLACEHOLDER,
  maxLength: SMS_CONTENT_MAXLEN,
  rows: 4,
  endpointUrl: (s: TProfileSettings) => organizationEndpointUrl(s.org),
}

export const SmsFirstContents: ITextInputProfile<IOrganizationPatch> = {
  ...SmsContentsMixin,
  type: EditType.INPUT_TEXT,
  info: langKeys.profiles_noise_monitoring_sms_guest_message_description,

  storedValue: (s) => {
    return (
      s.org.guest_communication?.disturbance_monitoring?.first_notice
        ?.sms_content || SMS_PLACEHOLDER
    )
  },
  payload: (sms_content: string) => {
    return {
      guest_communication: {
        disturbance_monitoring: { first_notice: { sms_content } },
      },
    }
  },
  endpointUrl: (s: TProfileSettings) => organizationEndpointUrl(s.org),
  id: "smscontent1",
}

export const SmsSecondContents: ITextInputProfile<IOrganizationPatch> = {
  ...SmsContentsMixin,
  type: EditType.INPUT_TEXT,
  info: langKeys.profiles_noise_monitoring_sms_guest_message_description,
  id: "smscontent2",

  storedValue: (s: TProfileSettings) => {
    return (
      s.org.guest_communication?.disturbance_monitoring?.second_notice
        ?.sms_content || SMS_PLACEHOLDER
    )
  },
  payload: (sms_content: string) => {
    return {
      guest_communication: {
        disturbance_monitoring: { second_notice: { sms_content } },
      },
    }
  },
}

export const SmsThirdContents: ITextInputProfile<IOrganizationPatch> = {
  ...SmsContentsMixin,
  type: EditType.INPUT_TEXT,
  info: langKeys.profiles_noise_monitoring_sms_guest_message_description,
  id: "smscontent3",

  storedValue: (s: TProfileSettings) => {
    return (
      s.org.guest_communication?.disturbance_monitoring?.third_notice
        ?.sms_content || SMS_PLACEHOLDER
    )
  },
  payload: (sms_content: string) => {
    return {
      guest_communication: {
        disturbance_monitoring: { third_notice: { sms_content } },
      },
    }
  },
}
