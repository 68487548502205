import { ISection } from "src/data/editField/editFieldTypes"
import {
  SECTION_FIRE_CO_KEY,
  SectionFireCO,
} from "src/data/homeProfileSettings/logic/sectionFireCO/sectionFireCO"
import { SectionNoiseMonitoring } from "src/data/homeProfileSettings/logic/sectionNoiseMonitoring/sectionNoiseMonitoring"
import { SectionSecurity } from "src/data/homeProfileSettings/logic/sectionSecurity/sectionSecurity"
import {
  TProfileSettings,
  TProfileSettingsPayload,
} from "src/data/homeProfileSettings/types/homeProfilesSettingTypes"

const SECTIONS: ISection<TProfileSettings, TProfileSettingsPayload>[] = [
  SectionNoiseMonitoring,
  SectionSecurity,
  SectionFireCO,
] as const

export function getSections({ hasUsedEureka }: { hasUsedEureka?: boolean }) {
  return SECTIONS.filter((s) => {
    if (s.key === SECTION_FIRE_CO_KEY) {
      return hasUsedEureka
    }
    return true
  })
}
