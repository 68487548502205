import { CircularProgress } from "@material-ui/core"
import { useQueryClient } from "@tanstack/react-query"

import { SettingsTabbed } from "src/components/Settings/SettingsTabbed"
import { MonitoringProfileSettings } from "src/components/SettingsProfiles/MonitoringProfileSettings"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { profilesCache } from "src/data/homeProfiles/queries/homeProfilesCache"
import { useFetchProfile } from "src/data/homeProfiles/queries/HomeProfilesQueries"
import {
  IProfile,
  IProfileResponse,
} from "src/data/homeProfiles/types/homeProfilesTypes"
import { getSections } from "src/data/homeProfileSettings/logic/homeProfileSettings"
import { SECTION_FIRE_CO_KEY } from "src/data/homeProfileSettings/logic/sectionFireCO/sectionFireCO"
import { TProfileSettingsResponse } from "src/data/homeProfileSettings/types/homeProfilesSettingTypes"
import { useFetchOrganization } from "src/data/organizations/queries/organizationQueries"
import { orgsKeys } from "src/data/organizations/queries/organizationQueryCache"
import { IOrganizationResponse } from "src/data/organizations/types/organizationTypes"
import { useFlags } from "src/hooks/useFlags"
import FireIcon from "src/ui/icons/eventIcons/fire-alarm.svg"
import Shield from "src/ui/icons/monochrome-shield.svg"
import Speaker from "src/ui/icons/monochrome-speaker.svg"
import { SvgPlaceholder } from "src/ui/icons/SvgPlaceholder"

function getIcon(key: string) {
  if (key === "section-security") {
    return <Shield width="24" height="24" />
  }
  if (key === "section-noise_monitoring") {
    return <Speaker width="24" height="24" />
  }
  if (key === SECTION_FIRE_CO_KEY) {
    return <FireIcon width="24" height="24" />
  }
  return <SvgPlaceholder height="24" width="24" />
}

export function EmbeddedSettingsProfile({
  initialProfile,
}: {
  initialProfile: IProfile
}) {
  const { showNewSettingsDesign } = useFlags()

  const hasUsedEureka = useFeatureAvailability({ feature: "eureka" })
  const queryClient = useQueryClient()
  const fetchProfile = useFetchProfile({
    id: initialProfile.id,
    options: { initialData: initialProfile },
  })
  const profile = fetchProfile.data
  const { data: org } = useFetchOrganization({
    id: initialProfile.organization_id,
  })

  async function onSubmitSuccess(
    response: TProfileSettingsResponse,
    url?: string
  ) {
    // PROTIP: Use the endpoints in in
    // `src/data/homeProfileSettings/logic/shared/endpoints.ts` to find which
    // settings depend on organization and which depend on profiles. When
    // removing EditField dependencies I suggest starting with the settings that
    // depend on `organizationEndpointUrl`, as they are quite few.

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
    const isOrg = (r: any): r is IOrganizationResponse =>
      !!url?.includes("organization")
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
    const isProfileResponse = (r: any): r is IProfileResponse =>
      !!url?.includes("profile")

    // Ensure that an EditField organization update also updates the react query
    // cache:
    if (isOrg(response)) {
      queryClient.setQueryData(
        orgsKeys.org(initialProfile.organization_id),
        response
      )
    }

    // Ensure that an EditField profile update also updates the react query
    // cache:
    if (isProfileResponse(response)) {
      queryClient.setQueryData(profilesCache.item(initialProfile.id), response)
    }
  }

  if (!org) {
    return <CircularProgress size={30} />
  }
  if (!profile) {
    return <CircularProgress size={30} />
  }

  return showNewSettingsDesign ? (
    <MonitoringProfileSettings
      profileResponse={profile}
      organizationResponse={org}
    />
  ) : (
    <SettingsTabbed
      views={getSections({ hasUsedEureka: hasUsedEureka.available })}
      currentSettings={[{ profile, org }]}
      iconMap={getIcon}
      config={{ onSubmitSuccess }}
    />
  )
}
