import { EditType } from "src/data/editField/editFieldTypes"
import { Feature } from "src/data/featureAvailability/types/featureAvailabilityTypes"
import {
  SmsFirstContents,
  SmsSecondContents,
  SmsThirdContents,
} from "src/data/homeProfileSettings/logic/sectionNoiseMonitoring/smsContents"
import { profileEndpointUrl } from "src/data/homeProfileSettings/logic/shared/endpoints"
import {
  IToggleProfile,
  IToggleSectionProfile,
  TProfileSettings,
} from "src/data/homeProfileSettings/types/homeProfilesSettingTypes"
import { langKeys } from "src/i18n/langKeys"

const ToggleSmsGuestMixin: {
  type: EditType.TOGGLE
  title: string
  info: string
  trueVal: true
  falseVal: false
  defaultValue: boolean
  endpointUrl: (s: TProfileSettings) => string
  inlineEdit: boolean
  feature: Feature
} = {
  type: EditType.TOGGLE,
  title: langKeys.guest_connect_sms_guest_title,
  info: langKeys.guest_connect_sms_guest_description,
  trueVal: true,
  falseVal: false,
  defaultValue: false,
  endpointUrl: (s) => profileEndpointUrl(s.profile),
  inlineEdit: true,
  feature: Feature.GUEST_CONNECT,
}

const ToggleSmsGuest1: IToggleProfile = {
  ...ToggleSmsGuestMixin,
  id: "sms-guest-toggle1",
  payload: (enabled: boolean) => {
    return {
      disturbance_monitoring: {
        first_notice_sms_enabled: enabled,
      },
    }
  },
  storedValue: (s): boolean =>
    !!s.profile.disturbance_monitoring?.first_notice_sms_enabled,
}

const ToggleSmsGuest2: IToggleProfile = {
  ...ToggleSmsGuestMixin,
  id: "sms-guest-toggle2",
  payload: (enabled: boolean) => {
    return {
      disturbance_monitoring: {
        second_notice_sms_enabled: enabled,
      },
    }
  },
  storedValue: (s): boolean =>
    !!s.profile.disturbance_monitoring?.second_notice_sms_enabled,
}

const ToggleSmsGuest3: IToggleProfile = {
  ...ToggleSmsGuestMixin,
  id: "sms-guest-toggle3",
  payload: (enabled: boolean) => {
    return {
      disturbance_monitoring: {
        third_notice_sms_enabled: enabled,
      },
    }
  },
  storedValue: (s): boolean =>
    !!s.profile.disturbance_monitoring?.third_notice_sms_enabled,
}

export const SmsToggleSection1: IToggleSectionProfile = {
  type: EditType.SECTION_TOGGLE,
  toggle: ToggleSmsGuest1,
  fields: [SmsFirstContents],
}

export const SmsToggleSection2: IToggleSectionProfile = {
  type: EditType.SECTION_TOGGLE,
  toggle: ToggleSmsGuest2,
  fields: [SmsSecondContents],
}

export const SmsToggleSection3: IToggleSectionProfile = {
  type: EditType.SECTION_TOGGLE,
  toggle: ToggleSmsGuest3,
  fields: [SmsThirdContents],
}
