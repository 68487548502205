import styled from "styled-components"

import { breakpoint } from "src/constants/breakpoints"
import { IProfileResponse } from "src/data/homeProfiles/types/homeProfilesTypes"
import { IOrganizationResponse } from "src/data/organizations/types/organizationTypes"
import { selectSettings } from "src/data/profileSettings/noiseAlertSettings"
import { TNoiseAlertVariant } from "src/data/profileSettings/noiseAlertTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { MInfo } from "src/ui/Info/MInfo"
import { MLink } from "src/ui/Link/MLink"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function NoiseAlertSettingCard({
  variant,
  profileResponse,
  organizationResponse,
}: {
  variant: TNoiseAlertVariant
  profileResponse: IProfileResponse
  organizationResponse: IOrganizationResponse
}) {
  const { tPlaceholder, t, langKeys } = useTranslate()

  const settings = selectSettings(
    variant,
    organizationResponse,
    profileResponse
  )
  const showToolTip = variant === "first_alert"

  function openDialog() {
    //TODO: GXP-396
  }

  function handleManageClick() {
    openDialog()
  }

  return (
    <MCard boxShadow={false} border padding={spacing.L}>
      <Content>
        <HeaderContent>
          <MText variant="heading3">{title(variant, tPlaceholder)}</MText>
          <MButton variant="subtle" size="small" onClick={handleManageClick}>
            {t(langKeys.manage)}
          </MButton>
        </HeaderContent>

        <SubtitleText variant="body">
          {description(variant, tPlaceholder)}{" "}
          {showToolTip && (
            <MInfo
              wrapperProps={{
                style: {
                  display: "inline",
                },
              }}
              interactive
              content={
                <>
                  <MText variant="bodyS">
                    {tPlaceholder(
                      "First alert is only triggered when the noise goes on for your set duration of time."
                    )}
                  </MText>
                  <MLink href="#">
                    {tPlaceholder("Edit duration in device settings.")}
                  </MLink>
                </>
              }
            />
          )}
        </SubtitleText>

        <PillsContent>
          <LabelledOnOffPill
            label={tPlaceholder("SMS to Guest")}
            isOn={settings.smsEnabled}
          />
          <LabelledOnOffPill
            label={tPlaceholder("Autocall")}
            isOn={settings.autocallEnabled}
          />
          <LabelledOnOffPill
            label={tPlaceholder("Flash & sound")}
            isOn={settings.flashEnabled}
          />
        </PillsContent>
      </Content>
    </MCard>
  )
}

function LabelledOnOffPill({ label, isOn }: { label: string; isOn: boolean }) {
  const { t, langKeys } = useTranslate()
  return (
    <PillContainer>
      <MText variant={isOn ? "subtitle" : "body"}>{label}</MText>
      <MBadge color={isOn ? "info" : "neutral"}>
        {isOn ? t(langKeys.on) : t(langKeys.off)}
      </MBadge>
    </PillContainer>
  )
}

function title(variant: TNoiseAlertVariant, t: (text: string) => string) {
  const titles: Record<TNoiseAlertVariant, string> = {
    first_alert: t("First alert"),
    second_alert: t("Second alert"),
    third_alert: t("Third alert"),
  }

  return titles[variant]
}

function description(variant: TNoiseAlertVariant, t: (text: string) => string) {
  const descriptions: Record<TNoiseAlertVariant, string> = {
    first_alert: t(
      "Select how to contact guests when noise is detected. You and your team will be notified automatically."
    ),
    second_alert: t(
      "Select how to contact guests 10 minutes after the first alert. You and your team will be notified automatically."
    ),
    third_alert: t(
      "Select how to contact guests 20 minutes after the first alert. You and your team will be notified automatically."
    ),
  }

  return descriptions[variant]
}

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const PillsContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.M};
  flex-wrap: wrap;
`

const PillContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};
  padding-top: ${spacing.XS};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.XS};
  container-type: inline-size;
`

const SubtitleText = styled(MText)`
  padding-top: ${spacing.XS};
  padding-bottom: 0;

  @container (${breakpoint.mediumUp}) {
    padding-top: 0;
  }
`
