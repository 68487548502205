import { EditType } from "src/data/editField/editFieldTypes"
import { Feature } from "src/data/featureAvailability/types/featureAvailabilityTypes"
import { profileEndpointUrl } from "src/data/homeProfileSettings/logic/shared/endpoints"
import {
  IToggleProfile,
  TProfileSettings,
} from "src/data/homeProfileSettings/types/homeProfilesSettingTypes"
import { langKeys } from "src/i18n/langKeys"

const FlashSoundMixin: {
  type: EditType.TOGGLE
  title: string
  info: string
  trueVal: true
  falseVal: false
  defaultValue: boolean
  endpointUrl: (s: TProfileSettings) => string
  inlineEdit: boolean
  feature: Feature
} = {
  type: EditType.TOGGLE,
  title: langKeys.guest_connect_sensor_reaction_title,
  info: langKeys.guest_connect_sensor_reaction_body,
  trueVal: true,
  falseVal: false,
  defaultValue: false,
  endpointUrl: (s) => profileEndpointUrl(s.profile),
  inlineEdit: true,
  feature: Feature.GUEST_CONNECT,
}

export const FlashSoundToggle1: IToggleProfile = {
  ...FlashSoundMixin,
  storedValue: (s): boolean =>
    !!s.profile.disturbance_monitoring?.first_notice_flash_and_sound,
  payload: (enabled: boolean) => {
    return {
      disturbance_monitoring: {
        first_notice_flash_and_sound: enabled,
      },
    }
  },
  id: "flash-sound-first-notice",
}

export const FlashSoundToggle2: IToggleProfile = {
  ...FlashSoundMixin,
  storedValue: (s): boolean =>
    !!s.profile.disturbance_monitoring?.second_notice_flash_and_sound,
  payload: (enabled: boolean) => {
    return {
      disturbance_monitoring: {
        second_notice_flash_and_sound: enabled,
      },
    }
  },
  id: "flash-sound-second-notice",
}

export const FlashSoundToggle3: IToggleProfile = {
  ...FlashSoundMixin,
  storedValue: (s): boolean =>
    !!s.profile.disturbance_monitoring?.third_notice_flash_and_sound,
  payload: (enabled: boolean) => {
    return {
      disturbance_monitoring: {
        third_notice_flash_and_sound: enabled,
      },
    }
  },
  id: "flash-sound-third-notice",
}
