import { AutoCallToggle } from "src/components/SettingsProfiles/NoiseMonitoring/AutoCallToggle"
import { CallAssistToggle } from "src/components/SettingsProfiles/NoiseMonitoring/CallAssistToggle"
import { EditType, IEditFieldNode } from "src/data/editField/editFieldTypes"
import { TProfileSettings } from "src/data/homeProfileSettings/types/homeProfilesSettingTypes"

export const AutoCallNode1: IEditFieldNode<TProfileSettings> = {
  type: EditType.NODE,
  key: "auto-call-node1",
  contents: (s) => <AutoCallToggle level={1} profileId={s.profile.id} />,
}

export const AutoCallNode2: IEditFieldNode<TProfileSettings> = {
  type: EditType.NODE,
  key: "auto-call-node2",
  contents: (s) => <AutoCallToggle level={2} profileId={s.profile.id} />,
}

export const AutoCallNode3: IEditFieldNode<TProfileSettings> = {
  type: EditType.NODE,
  key: "auto-call-node3",
  contents: (s) => <AutoCallToggle level={3} profileId={s.profile.id} />,
}

export const CallAssistNode3: IEditFieldNode<TProfileSettings> = {
  type: EditType.NODE,
  key: "callassist-node3",
  contents: (s) => <CallAssistToggle level={3} profileId={s.profile.id} />,
}
