export function SvgPlaceholder({
  width,
  height,
  text,
  bgColor = "gray",
  textColor = "white",
  fontFamily = "monospace",
  fontSize = "inherit",
  fontWeight = "inherit",
  dy = "10%",
  ...props
}: {
  width: number | string
  height: number | string
  text?: string
  bgColor?: string
  textColor?: string
  dy?: string
  fontSize?: string
  fontFamily?: string
  fontWeight?: string
}) {
  const _text = text || `${height}x${width}`
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
    >
      <rect fill={bgColor} width={width} height={height} />
      <text
        fontFamily={fontFamily}
        fontSize={fontSize}
        fontWeight={fontWeight}
        fill={textColor}
        dy={dy}
        x="50%"
        y="50%"
        textAnchor="middle"
      >
        {_text}
      </text>
    </svg>
  )
}
